.__device-container {
    @apply relative inline-block h-full;

    --devices-scale: 0.6;
    --img-scale: 1;

    @media (min-width: 768px) {
        --devices-scale: 0.8;
    }

    @media (min-width: 1024px) {
        --devices-scale: 1;
    }

    .__device-size {
        width: calc(var(--devices-scale) * 428px);
        height: calc(var(--devices-scale) * 868px);
    }

    .__device-frame-size {
        border: calc(var(--devices-scale) * 1px);
        border-radius: calc(var(--devices-scale) * 68px);
        box-shadow:
            inset 0 0 calc(var(--devices-scale) * 2px)
                calc(var(--devices-scale) * 2px) #c8cacb,
            inset 0 0 0 calc(var(--devices-scale) * 7px) #e2e3e4;
        padding: calc(var(--devices-scale) * 28px);
        width: calc(var(--devices-scale) * 428px);
        height: calc(var(--devices-scale) * 868px);
    }

    .__device-header-size {
        border-bottom-left-radius: calc(var(--devices-scale) * 20px);
        border-bottom-right-radius: calc(var(--devices-scale) * 20px);
        height: calc(var(--devices-scale) * 30px);
        left: 50%;
        margin-left: calc(var(--devices-scale) * -102px);
        position: absolute;
        top: calc(var(--devices-scale) * 28px);
        width: calc(var(--devices-scale) * 204px);

        &::before {
            right: calc(var(--devices-scale) * -10px);
            width: calc(var(--devices-scale) * 10px);
            height: calc(var(--devices-scale) * 10px);
        }

        &::after {
            left: calc(var(--devices-scale) * -10px);
            width: calc(var(--devices-scale) * 10px);
            height: calc(var(--devices-scale) * 10px);
        }
    }

    .__device-sensors-size {
        &::before {
            top: calc(var(--devices-scale) * 30px);
            margin-left: calc(var(--devices-scale) * 42px);
            width: calc(var(--devices-scale) * 10px);
            height: calc(var(--devices-scale) * 10px);
        }

        &::after {
            border-radius: calc(var(--devices-scale) * 3px);
            height: calc(var(--devices-scale) * 6px);
            margin-left: calc(var(--devices-scale) * -25px);
            top: calc(var(--devices-scale) * 32px);
            width: calc(var(--devices-scale) * 50px);
        }
    }

    .__device-stripe-size {
        &::before {
            bottom: calc(var(--devices-scale) * 85px);
            border-width: 0 calc(var(--devices-scale) * 7px);
            height: calc(var(--devices-scale) * 7px);
        }

        &::after {
            top: calc(var(--devices-scale) * 85px);
            border-width: 0 calc(var(--devices-scale) * 7px);
            height: calc(var(--devices-scale) * 7px);
        }
    }

    .__device-screen-size {
        @apply transform-gpu origin-center;

        border-radius: calc(var(--devices-scale) * 40px);
        transform: scale(var(--img-scale));
        height: auto;
        width: auto;
    }

    .__gradient {
        @apply absolute bottom-0 left-0 h-2/3 w-full bg-gradient-to-t from-stratos-950 z-10 via-stratos-950/80;
    }

    &.--sm {
        --devices-scale: 0.6;
    }
}
